import { useEffect, useRef, useState } from 'react';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';

type ResourceValue = Record<string, any>|any[];

export function useResource<T>(url?: string, localValue?: ResourceValue) {
  const resource = useRef<Resource>(new Resource(url, localValue));
  const [resourceState, setResourceState] = useState<any>(null);
  useEffect(() => {
    const subscription = resource.current.resource$.subscribe((resource) => {
      setResourceState(resource);
    });
    resource.current.pull();
    return () => subscription.unsubscribe();
  }, []);

  return {
    resource: resourceState as T,
    pull: () => resource.current.pull(),
  };
}

class Resource {
  private urlLoader = new Subject<string|null>();
  private subscription: Subscription|null = null;

  url: string|null;
  localValue: ResourceValue|null;
  resource$ = new BehaviorSubject<ResourceValue|null>(null);
  constructor(url?: string, localValue?: ResourceValue) {
    this.url = url || null;
    this.localValue = localValue || null;
    this.resource$.next(this.localValue);
    this.subscription = this.urlLoader.pipe(switchMap(url => this.fetch(url))).subscribe(resourceValue => this.merge(resourceValue));
  }

  async fetch(url: string|null = null): Promise<ResourceValue|null> {
    if (url === null) { url = this.url; }
    if (!url) { return null; }
    const request = new Request(url);
    try {
      const response = await fetch(request);
      if (response.status !== 200) {
        throw new Error(`Resource response status code ${response.status} for URL ${request.url}`);
      }
      const responseBody = await response.json();
      return responseBody as ResourceValue;
    } catch (e) {
      console.log('Resource fetch error', e);
      return null;
    }
  }

  async pull(url: string|null = null) {
    this.urlLoader.next(url);
  }

  merge(resource: ResourceValue|null) {
    if (!resource) { return; }
    const currentValue = this.resource$.value;
    let nextValue = resource;
    if (Array.isArray(currentValue)) {
      if (Array.isArray(resource)) {
        nextValue = currentValue.concat(resource);
      }
    } else if (currentValue && !Array.isArray(resource)) {
      nextValue = Object.assign({}, currentValue, resource);
    }
    this.resource$.next(nextValue);
  }

  set(resource: ResourceValue|null) {
    this.urlLoader.next('');
    this.resource$.next(resource);
  }

  destroy() {
    this.subscription?.unsubscribe();
    this.subscription = null;
  }
}

export default Resource;
