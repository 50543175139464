import React from 'react'
import { Link } from 'react-router-dom';
import env from '../hooks/env';
import { useResource } from '../hooks/useResource'
import styles from '../styles/components/Home.module.scss';

interface HomeContent {
  main_image: {
    url: string;
  }
}

export default function Home() {
  const { resource: content } = useResource<HomeContent>(`${env.API_URL}/home`);
  return <div className={styles.body}>
    {content && 
      <div className={styles.mainImageContainer}>
        <Link className={styles.mainImageBlur} to={`/collections/mobiles`}></Link>
        <img className={styles.mainImage} src={env.API_URL + content.main_image.url} alt='large-logo' />
      </div>
    }
  </div>
}