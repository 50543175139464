import React from 'react'
import { Redirect, Route, Switch, useParams } from 'react-router-dom';
import { BlogCategory } from './models/blog-category.model';
import { Category } from './models/category.model';
import { BlogSection } from './pages/BlogSection';
import { Collection } from './pages/Collection';
import Home from './pages/Home';

export default function Routes(props: {
  blogCategories?: BlogCategory[]|null,
  categories?: Category[]|null,
}) {
  return (
    <Switch>
      <Route exact path='/collections/:slug'>
        <RouteCollections categories={props.categories}/>
      </Route>
      <Route exact path='/blog/:slug'>
        <RouteBlogSections categories={props.blogCategories}/>
      </Route>
      <Route exact path='/'> 
        <Home></Home>
      </Route>
      <Route path='*'><Redirect to ='/'/></Route>
    </Switch>
  )
}

function RouteCollections(props: { categories?: Category[]|null }) {
  const { slug } = useParams<{ slug: string }>()
  if (!props.categories) return <></>;
  const category = props.categories.find((c => c.slug === slug));
  if (!category) return <Redirect to='/'/>;
  return <Collection category={category}></Collection>}

function RouteBlogSections(props: { categories?: BlogCategory[]|null }) {
  const { slug } = useParams<{ slug: string }>()
  if (!props.categories) return <></>;
  const category = props.categories.find((c => c.slug === slug));
  if (!category) return <Redirect to='/'/>;
  return <BlogSection category={category}></BlogSection>

}