import React, { useEffect, useState } from 'react'
import env from '../hooks/env';
import { Category } from '../models/category.model'
import styles from '../styles/components/Collection.module.scss';
import { Galleria } from 'primereact/galleria';
import { Product } from '../models/product.model';
import { useKey } from '../hooks/useKey';
import CollectionProduct from '../components/CollectionProduct';

export function Collection(props: { category: Category }) {
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [selectedProduct, setSelectedProduct] = useState<Product|null>(null);
  useEffect(() => { setSelectedProduct(null); }, [props.category])
  useEffect(() => { setActiveIndex(0); }, [selectedProduct])
  useKey({
    Escape: () => setSelectedProduct(null),
    ArrowRight: () => {
      if (selectedProduct?.gallery?.length) {
        const i = activeIndex < selectedProduct.gallery.length ? activeIndex + 1 : 0;
        console.log(i);
        setActiveIndex(i);
      }
    },
    ArrowLeft: () =>  {
      if (selectedProduct?.gallery?.length) {
        const i = activeIndex > 0 ? activeIndex - 1 : selectedProduct.gallery.length;
        console.log(i);
        setActiveIndex(i);
      }
    },
  });

  return <>
    {
      selectedProduct && <Galleria
        numVisible={8}
        activeIndex={activeIndex}
        onItemChange={e => setActiveIndex(e.index)}
        className={styles.gallery}
        value={[selectedProduct.image, ...selectedProduct.gallery]}
        header={
          <div className={styles.header}>
            <h1 className={styles.title}>
              {selectedProduct.title}
            </h1>
            <button onClick={() => setSelectedProduct(null)}><i className='pi pi-times'></i></button>
          </div>
        }
        item={(item: any) => {
          console.log(item.url);
          if ((item.url as string).match(/.*\.(mov|mp4)$/)) {
            return <div>
              <video className={styles.item} width="80%" src={env.API_URL + item.url} controls autoPlay></video>
            </div>
          }
          return <img className={[styles.item, styles.image].join(' ')} src={env.API_URL + item.url} alt={item.title}/>
        }}
        thumbnail={(item: any) => {
          if ((item.url as string).match(/.*\.(mov|mp4)$/)) {
            return <div className={styles.videoThumbnail}>
              <div className={styles.videoOverlay}>
                <i className={['pi pi-play', styles.videoOverlayIcon].join(' ')}></i>
              </div>
              <video className={[styles.video].join(' ')} width="80%" src={env.API_URL + item.url} ></video>
            </div>
          }
          return <div className={styles.thumbnail} style={{ backgroundImage: `url(${env.API_URL}${item.url})`}}></div>
        }}
        thumbnailsPosition='bottom'
        showItemNavigators={true}
        showThumbnailNavigators={false}
        changeItemOnIndicatorHover={true}
        circular={true}
        >
      </Galleria>
    }
  <div className={[styles.collection].join(' ')}>
    {
      props.category.products.map(product => <CollectionProduct
        key={product.id}
        item={product}
        setSelectedProduct={setSelectedProduct}
        />)
    }
  </div>
  </>
}