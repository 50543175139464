import React, { useState } from 'react'
import { Link, RouteProps, useLocation } from 'react-router-dom';
import logo from '../assets/elmwood.png';
import { BlogCategory } from '../models/blog-category.model';
import { Category } from '../models/category.model';
import styles from '../styles/components/Header.module.scss';

function Menu(props: {
  hidden: boolean,
  categories?: Category[]|null,
  blogCategories?: BlogCategory[]|null,
  onMouseLeave: () => void,
}) {
  return <div className={[styles.slidingMenu, props.hidden ? '' : styles.open].join(' ')} id='sliding-menu' onMouseLeave={() => props.onMouseLeave()}>
    <Link to='/'>
      <div className={styles.logoContainer}>
        <img className={styles.logo} alt="logo" src={logo}/>
        <div className={styles.siteName}>elmwood</div>
      </div>
    </Link>
    <div className={styles.primaryNavContainer}>
      {props.categories && props.categories.map(category => <div 
        key={category.id}
        className={styles.navItem}
        >
          <Link to={`/collections/${category.slug}`}>
            <div className={[styles.icon, styles[category.slug]].join(' ')}>
              <span className={styles.name}>{category.name}</span>
              <div className={styles.preloader}></div>
            </div>
          </Link>
        </div>
      )}
    </div>
    <div className={styles.secondaryNavContainer}>
      {props.blogCategories && props.blogCategories
        .filter(category => category.blog_posts.length)
        .map((category, i) => <div
          key={category.id}
          className={styles.navItem}
          >
            <Link to={`/blog/${category.slug}`}>
              {category.name}
            </Link>
          </div>)}
    </div>
  </div>
}

export function Header(props: {
  categories: Category[],
  blogCategories: BlogCategory[],
}) {
  const location = useLocation();
  const [ menuHidden, setMenuHidden ] = useState<boolean>(true);

  return <>
    <header>
      <div className={styles.pageHeader} onMouseEnter={() => setMenuHidden(false)}>
        <img className={styles.logo} alt="logo" src={logo} />
        <PageName
          blogCategories={props.blogCategories}
          categories={props.categories}
          location={location}
          />
      </div>
      <div
        className={styles.breathingArrow}
        onMouseEnter={() => setMenuHidden(false)}
        >
      </div>
      <div className={styles.menuContainer}>
        <Menu
          categories={props.categories}
          blogCategories={props.blogCategories}
          hidden={menuHidden}
          onMouseLeave={() => setMenuHidden(true)}
          />
      </div>
    </header>
    <div className={styles.headerSpace}></div>
  </>
}

export function PageName(props: {
  location: RouteProps['location'],
  categories: Category[],
  blogCategories: BlogCategory[],
}) {
  if (!props.location) return <></>;
  const { pathname } = props.location;
  const [ , section, slug ] = pathname.split('/');
  const getName = () => {
    switch (section) {
      case 'collections':
        const category = props.categories && props.categories.find(c => c.slug === slug);
        return category ? category.name : '';
      case 'blog':
        const blogCategory = props.blogCategories && props.blogCategories.find(c => c.slug === slug);
        return blogCategory ? blogCategory.name : '';
    }
  };
  return <div className={styles.pageName}>
    { getName()?.toLowerCase() }
  </div>
}