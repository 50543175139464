import { mapKeys } from 'lodash-es';

export interface EnvironmentVariables {
  API_URL: string;
}

const env: EnvironmentVariables = {
  ...{
    API_URL: 'localhost:1337',
  },
  ...mapKeys(process.env, (v, k) => k.replace('REACT_APP_', '')),
};

export default env;