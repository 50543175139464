import React from 'react'
import './App.css';
import Routes from './Routes';
import { useResource } from './hooks/useResource';
import { Category } from './models/category.model';
import { Header } from './components/Header';
import { BlogCategory } from './models/blog-category.model';
import env from './hooks/env';

function App() {
  const { resource: categories } = useResource<Category[]>(`${env.API_URL}/categories`);
  const { resource: blogCategories } = useResource<BlogCategory[]>(`${env.API_URL}/blog-categories`);
  return (
    <>
      <Header blogCategories={blogCategories} categories={categories} />
      <Routes blogCategories={blogCategories} categories={categories} />
      <div className="footer-space"></div>
      <div className="footer">
        <a target="_blank" rel="noopener noreferrer" href="mailto:emily.s.swierenga@gmail.com">email me</a>
        <a className="social" href="https://www.instagram.com/elmwoodmakes/">
          <img src="/instagram.png" alt="instagram"/>
        </a>
      </div>
    </>
  );
}

export default App;
