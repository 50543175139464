import { useEffect } from "react";

export function useKey(keymap: Record<string, () => void>) {
  useEffect(() => {
    const onKeyUp = ({ key }: any) => {
      const handler = keymap[key];
      if (typeof handler === 'function') {
        handler();
      }
    }
    document.addEventListener('keyup', onKeyUp);
    return () => {
      document.removeEventListener('keyup', onKeyUp);
    }
  })
}
