import * as React from 'react';
import { Product } from '../models/product.model';
import styles from '../styles/components/CollectionProduct.module.scss';
import env from '../hooks/env';
import { useState } from 'react';

export interface CollectionProductProps {
  item: Product;
  setSelectedProduct: (item: Product) => void;
}

export default function CollectionProduct({ item, setSelectedProduct }: CollectionProductProps) {
  const [expand, setExpand] = useState<boolean>(false);
  const onMouseEnter = (e: React.MouseEvent) => {
    setExpand(true);
  }
  const onMouseLeave = (e: React.MouseEvent) => {
    setExpand(false);
  }
  return (<div
    className={[styles.product].join(' ')}
    onClick={() => setSelectedProduct(item)}
    onMouseLeave={onMouseLeave}
    >
    <div
      className={styles.image} style={{ backgroundImage: `url(${env.API_URL}${item.image.url})`}}
      onMouseEnter={onMouseEnter}
      />
    <div className={[styles.title, expand ? styles.expand : ''].join(' ')}>
      {item.title}
      <div className={styles.moreButton}>
        <i className='pi pi-images'></i>
        <i className='pi pi-angle-right'></i>
      </div>
    </div>
  </div>)
}